





































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";

@Observer
@Component({
  components: {
    CharacterAvatar: () =>
      import("@/components/character/character-avatar.vue"),
  },
})
export default class IncreaseUnlockTime extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;
}
